import '../app/css/pages/authentication.css';

import Cleave from 'cleave.js';

$(function () {
    let pageLoginForm = $('.auth-login-form');
    let pageForgotPasswordForm = $('.auth-forgot-password-form');
    let pageResetPasswordForm = $('.auth-reset-password-form');
    let pageCheckpointForm = $('.auth-checkpoint-form');

    if (pageLoginForm.length) {
        pageLoginForm.validate({
            rules: {
                'username': {
                    required: true
                },
                'login-password': {
                    required: true
                }
            }
        });
    }

    if (pageForgotPasswordForm.length) {
        pageForgotPasswordForm.validate({
            rules: {
                'forgot-password-email': {
                    required: true
                }
            }
        });
    }

    if (pageResetPasswordForm.length) {
        pageResetPasswordForm.validate({
            rules: {
                'password1': {
                    required: true
                },
                'password2': {
                    required: true,
                    equalTo: '#password1'
                }
            }
        });
    }

    if (pageCheckpointForm.length) {
        let inputContainer = document.querySelector('.auth-input-wrapper');

        inputContainer.onkeyup = function (e) {
            let target = e.target;
            let maxLength = parseInt(target.attributes.maxlength.value, 10);
            let currentLength = target.value.length;

            if (e.keyCode === 8) {
                if (currentLength === 0) {
                    let next = target;
                    while ((next = next.previousElementSibling)) {
                        if (next.tagName.toLowerCase() === 'input') {
                            next.focus();
                            break;
                        }
                    }
                }
            } else {
                if (currentLength >= maxLength) {
                    let next = target;

                    if ($(next).prop('name') === 'part6') {
                        pageCheckpointForm.submit();
                    }

                    while ((next = next.nextElementSibling)) {
                        if (next.tagName.toLowerCase() === 'input') {
                            next.focus();
                            break;
                        }
                    }
                }
            }
        };

        const numeralMask = document.querySelectorAll('.numeral-mask');

        if (numeralMask.length) {
            numeralMask.forEach(e => {
                new Cleave(e, {
                    numeral: true
                });
            });
        }

        pageCheckpointForm.validate({
            rules: {
                '2fa_code': {
                    required: true
                }
            }
        });
    }
});
